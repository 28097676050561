import { Component, Input } from "@angular/core";
import { DefaultComponent } from "src/app/default.component";
import { PrefixGeoComponent } from "../../global/prefixes/impl/prefix-geo/prefix-geo.component";
import { Marker } from "../../global/prefixes/templates/template-map/template-map.component";

@Component({
  selector: "app-map",
  imports: [PrefixGeoComponent],
  templateUrl: "./map.component.html",
  styleUrl: "./map.component.less",
})
export class MapComponent extends DefaultComponent {
  @Input({ required: true })
  public markers: Marker[];

  public constructor() {
    super();
    this.markers = [];
  }
}
