import { DecimalPipe } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { DefaultComponent } from "src/app/default.component";
import { PrefixTemplate } from "../../PrefixTemplate";

export interface ProgressValue {
  start: number;
  end: number;
  current: number;
  unit: string;
  type: number;
}

@Component({
  selector: "app-template-progress",
  imports: [MatProgressBarModule, MatProgressSpinnerModule, DecimalPipe],
  templateUrl: "./template-progress.component.html",
  styleUrl: "./template-progress.component.less",
})
export class TemplateProgressComponent extends DefaultComponent implements PrefixTemplate<number, null>, OnInit {
  @Input({ required: true })
  public control: FormControl<number | null> | null;

  public value: null;

  @Input()
  public start: number | null;

  @Input()
  public end: number | null;

  @Input()
  public unit: string | null;

  @Input()
  public type: number | null;

  @Input()
  public prefix: string | null;

  public progress: number | null;

  public constructor() {
    super();

    this.control = null;
    this.value = null;
    this.start = null;
    this.end = null;
    this.prefix = null;
    this.unit = null;
    this.type = null;
    this.progress = null;
  }

  public ngOnInit(): void {
    if (this.control) {
      this.setProgress(this.control.value);
      this.addSubscription(
        this.control.valueChanges.subscribe((value) => {
          this.setProgress(value);
          console.error("templtate update!");
        }),
      );
    }

    console.log("TEMPLATE => ", {
      control: this.control,
      value: this.value,
      start: this.start,
      end: this.end,
      prefix: this.prefix,
      unit: this.unit,
      type: this.type,
      progress: this.progress,
    });
  }

  public setProgress(value: number | null): void {
    if (value && this.end != null && this.start != null) {
      this.progress = (100 / (this.end - this.start)) * (value - this.start);
    }
  }
}
