import { Component, OnInit, inject } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { TableRow } from "src/app/components/default/table/table-row";
import { ROUTES_CONFIG } from "src/config/routes.config";
import { ActionResponse, ParsedActionResponse } from "src/interfaces/post-request/post-request";
import { ApplicationService } from "src/services/application.service";
import { HttpService } from "src/services/http.service";
import { SessionService } from "src/services/session.service";
import { DialogTitle } from "../../template/dialog-template.component";
import { DialogComponent } from "../dialog.component";

export interface TableDeleteWarningDialogData {
  title: DialogTitle;
  rows: TableRow[];
}

@Component({
  selector: "app-table-delete-warning",
  imports: [TranslateModule],
  templateUrl: "./table-delete-warning.component.html",
  styleUrl: "./table-delete-warning.component.less",
})
export class TableDeleteWarningComponent extends DialogComponent<TableDeleteWarningDialogData> implements OnInit {
  public session: SessionService;
  public http: HttpService;
  public application: ApplicationService;

  public rows: TableRow[];

  public constructor() {
    super();
    this.session = inject(SessionService);
    this.http = inject(HttpService);
    this.application = inject(ApplicationService);

    this.rows = [];
  }

  public ngOnInit(): void {
    super.ngOnInit();
    const data = this.data;

    if (data) {
      this.rows = data.rows;
    } else {
      throw new Error("Undefined data");
    }
  }

  public async confirm(): Promise<void> {
    const rows: TableRow[] = [];
    let actionID: string = "";
    for (const row of this.rows) {
      rows.push(row);
      row.delete();
      actionID = row.deletelink;
    }
    const data = rows.reduce((total, current, index) => ({ ...total, [`deletelink${index}`]: current.deletelink }), {});
    const dataresponse = await this.http.send<ActionResponse>(ROUTES_CONFIG.actionurl, {
      FFWDActionID: actionID,
      actionData: JSON.stringify(data),
      tabindex: this.application.index,
    });
    const response = <ParsedActionResponse>JSON.parse(dataresponse.postActionAsJSON);
    this.application.onMessage(response.messages);

    this.close();
  }

  public deny(): void {
    this.close();
  }
}
