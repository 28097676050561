import { Component, Input } from "@angular/core";
import { DefaultComponent } from "src/app/default.component";
import { IconReplacePipe } from "src/pipes/icon-replace.pipe";
import { TablePrefixComponent } from "../../global/prefixes/containers/table-prefix/table-prefix.component";
import { Tile } from "./tile.interface";

@Component({
  selector: "app-tile",
  imports: [TablePrefixComponent, IconReplacePipe],
  templateUrl: "./tile.component.html",
  styleUrl: "./tile.component.less",
})
export class TileComponent extends DefaultComponent {
  @Input({ required: true })
  public tiles: Tile[];

  public constructor() {
    super();
    this.tiles = [];
  }

  public createIconElement(iconClassList: string): string {
    return `<i class="${iconClassList}"></i>`;
  }
}
