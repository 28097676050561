import { Component, Input, OnInit } from "@angular/core";
import { PrefixComponent } from "../../prefix.component";
import { ProgressValue, TemplateProgressComponent } from "../../templates/template-progress/template-progress.component";

@Component({
  selector: "app-prefix-pge",
  imports: [TemplateProgressComponent],
  templateUrl: "./prefix-pge.component.html",
  styleUrl: "./prefix-pge.component.less",
})
export class PrefixPgeComponent extends PrefixComponent<number, ProgressValue> implements OnInit {
  @Input()
  public options: ProgressValue | null;

  public start: number;
  public end: number;
  public type: number;
  public unit: string;
  public prefix: string;

  public constructor() {
    super();

    this.options = null;
    this.start = 0;
    this.end = 100;
    this.type = 1;
    this.unit = "{unit}";
    this.prefix = "€";
  }

  public ngOnInit(): void {
    super.ngOnInit();

    if (this.options != null) {
      this.start = this.options.start;
      this.end = this.options.end;
      this.type = this.options.type;
      this.unit = this.options.unit;
    }
  }
}
