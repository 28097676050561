import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { TableField } from "src/app/components/default/table/table-field";
import { DefaultComponent } from "src/app/default.component";
import { PrefixContainerDirective } from "src/directives/prefix-container.directive";
import { PrefixXxxComponent } from "../../impl/prefix-xxx/prefix-xxx.component";
import { PrefixComponent, PrefixEvent } from "../../prefix.component";
import { TABLE_PREFIX_DATA } from "./table-prefix-data";

@Component({
  imports: [PrefixContainerDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "app-table-prefix",
  templateUrl: "./table-prefix.component.html",
  styleUrl: "./table-prefix.component.less",
})
export class TablePrefixComponent extends DefaultComponent implements OnInit {
  @Input({ required: true })
  public field: TableField | null;

  @ViewChild(PrefixContainerDirective, { static: true })
  private prefixContainer: PrefixContainerDirective | null;

  @Output()
  public prefixevent: EventEmitter<PrefixEvent>;

  public constructor() {
    super();
    this.field = null;
    this.prefixevent = new EventEmitter();

    this.prefixContainer = null;
  }

  public ngOnInit(): void {
    if (this.field) this.render(this.field);
  }

  public render(field: TableField): void {
    if (this.prefixContainer) {
      let prefix = TABLE_PREFIX_DATA[field.template];
      const container = this.prefixContainer.viewContainerRef;

      if (!prefix) {
        console.warn(field.template, " has not been implemented into the table yet.");
        prefix = { class: PrefixXxxComponent, inputs: {} };
      }

      const component = container.createComponent(prefix.class);
      const instance = <PrefixComponent<unknown>>component.instance;

      for (const [name, property] of Object.entries(prefix.inputs)) {
        if (field.extras) {
          component.setInput(name, field.extras[property] ?? property);
        }
      }

      component.setInput("disabled", true);
      component.setInput("value", field.value.value);

      this.addSubscription(instance.event.subscribe((event) => this.prefixevent.emit(event)));
    } else {
      throw new Error("error rendering prefix");
    }
  }
}
