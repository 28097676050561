import { HttpResponse } from "@angular/common/http";
import { ButtonAction } from "src/app/components/default/buttons/buttons.component";
import { ProgressResponse } from "src/app/components/default/form/form.interface";
import { TableField } from "src/app/components/default/table/table-field";
import { TableGroup } from "src/app/components/default/table/table-group";
import { TableHeader, TableSortDirection } from "src/app/components/default/table/table-header";
import { TableRow } from "src/app/components/default/table/table-row";
import { TABLE_TEMPLATE } from "src/app/components/default/table/table-template";
import { TableAction } from "src/app/components/default/table/table.component";
import {
  TableContentPart,
  TableOptions,
  TableResponseAttachmentLink,
  TableResponseButton,
  TableResponseData,
  TableResponseField,
  TableResponseGroup,
  TableResponseHeader,
  TableResponseRow,
} from "src/app/components/default/table/table.interface";
import { AttachmentLink } from "src/app/components/global/prefixes/impl/prefix-bes/prefix-bes.component";
import { ProgressValue } from "src/app/components/global/prefixes/templates/template-progress/template-progress.component";
import { FileManager } from "src/classes/FilesManager";
import { ROUTES_CONFIG } from "src/config/routes.config";
import { ActionResponse } from "src/interfaces/post-request/post-request";
import { ApplicationService } from "src/services/application.service";
import { HttpService } from "src/services/http.service";
import { SessionService } from "src/services/session.service";
import { ContentPart } from "../content-part";
import { ContentPartType } from "../content.component";

export class ContentTable extends ContentPart {
  private application: ApplicationService;
  private http: HttpService;

  public switchactive: boolean;
  public filteractive: boolean;
  public activeFilters: TableContentPart["groupfilters"];

  public options: Partial<TableOptions>;

  public buttons: TableResponseButton[];
  public headers: Map<number, TableHeader>;
  public fields: Map<number, TableGroup>;

  public statusactionid: string;
  public searchactionid: string;
  public sortactionid: string;
  public searchkeys: string[];

  public tabindex: string | null;
  public contentid: string | null;
  public contentpartid: string | null;
  public session?: SessionService;

  public constructor(id: string, http: HttpService, application: ApplicationService, type: ContentPartType = "Table", session?: SessionService) {
    super(id, type);
    this.http = http;
    this.application = application;
    this.session = session;

    this.switchactive = false;
    this.filteractive = false;
    this.activeFilters = [];

    this.options = {};

    this.buttons = [];
    this.headers = new Map<number, TableHeader>();
    this.fields = new Map<number, TableGroup>();

    this.statusactionid = "";
    this.searchactionid = "";
    this.sortactionid = "";
    this.searchkeys = [];

    this.tabindex = null;
    this.contentid = null;
    this.contentpartid = null;
  }

  public async onEvent(event: TableAction): Promise<void> {
    const type = event.type;
    const table = event.table;
    const params = event.params;
    const selectedRows = table.selectedRows;

    this.application.loading.next(true);
    switch (type) {
      case ButtonAction.SNAPSHOT:
        await this.onSnapshot(<string>params.shift(), <string | null>params.shift());
        break;

      case ButtonAction.REDIRECT:
        await this.onRedirect(<string>params.shift());
        break;

      case ButtonAction.ACTION:
        await this.onAction(<string>params.shift(), selectedRows);
        break;

      case ButtonAction.DOWNLOAD:
        <null>params.shift(); //actionid
        await this.onDownload(selectedRows, <string | null>params.shift());
        break;

      case ButtonAction.DELETE:
        table.deleteRows();
        break;

      default:
        console.warn("[FORM] Unknown table action => ", {
          type,
          table,
          params,
        });
        break;
    }
    this.application.loading.next(false);
  }

  /**
   * Parse TableReponseData into useable filters map
   * @param data
   * @returns
   */
  public parseFilters(group: TableResponseGroup): boolean {
    return (
      this.activeFilters.find((filter) => filter.level === group.level)?.filters.find((filter) => filter.value === group.value)?.active === "false" ||
      false
    );
  }

  public getFilterName(group: TableResponseGroup): string {
    return (
      this.activeFilters.find((_filter) => _filter.level === group.level)?.filters.find((_filter) => _filter.value === group.value)?.leveldesc ?? ""
    );
  }

  /**
   * Parse TableReponseData into useable fields map
   * @param data
   * @returns
   */
  public parseFields(data: TableResponseData): Map<number, TableGroup> {
    const map = new Map<number, TableGroup>();
    const group = new TableGroup("Algemeen");

    if (data) {
      group.rows = this.parseRows(data.rows);
      group.groups = this.parseGroups(data.groups);
    }

    map.set(0, group);

    return map;
  }

  /**
   * Parse TableResponseHeader[] into useable headers map
   * @param data
   * @returns
   */
  public parseHeaders(data: TableResponseHeader[]): Map<number, TableHeader> {
    const headers = new Map<number, TableHeader>();

    if (data)
      for (const [index, head] of data.entries()) {
        const width = head?.width;
        const label = this.sanitizeName(head);
        const header = new TableHeader(label, { icon: head.headericon, width: width ? `${width}px` : undefined });
        if (head.sortorder) {
          header.sort = {
            column: head.sortcolumn || null,
            direction:
              head.sortorder && ["asc", "desc", "initial"].includes(head.sortcolumn) ? <TableSortDirection>head.sortcolumn.toUpperCase() : "INITIAL",
          };
        }
        header.visible = head.hidecolumn == "0";
        header.template = head.pfx;
        headers.set(index, header);
      }

    return headers;
  }

  /**
   * Set correct values
   * @param field
   * @returns
   */
  public sanitizeValue(field: TableResponseField): unknown {
    switch (field.pfx) {
      case "img":
        return (<string>field.value).split("|")[0];

      case "prg":
      case "pge":
        return (<ProgressValue>(<unknown>this.parseProgress(<ProgressResponse>field.value))).current;

      default:
        return field.value;
    }
  }

  /**
   * Create the extras object depending on prefix
   * @param field
   * @returns
   */
  public getExtras(field: TableResponseField): Record<string, unknown> {
    switch (field.pfx) {
      case "img":
        return this.parseImage(<string>field.value);

      case "lnk":
      case "ln3":
      case "lna":
      case "idp":
        return this.parseLink(field.l);

      case "prg":
      case "pge":
        return { options: this.parseProgress(<ProgressResponse>field.value) };

      case "bes":
        return { attachmentLinks: this.parseAttachmentLinks(field.a) };

      default:
        return {};
    }
  }

  /*
   * parses data into rows
   * @param rows
   * @param data
   */
  private parseRows(data: TableResponseRow[]): Map<number, TableRow> {
    const map = new Map<number, TableRow>();
    for (const [index, _row] of data.entries()) {
      const row = new TableRow();
      row.deletelink = _row.deletelink;
      row.downloadlink = _row.downloadlink;

      for (const [fieldIndex, field] of _row.fields.entries()) {
        const head = this.headers.get(fieldIndex);
        if (head) {
          let tooltip: string | null = null;
          tooltip = field.tt.map((tooltip) => tooltip.value).join("<br />");
          if (field.a.length) {
            console.log("[a]", field.a);
          }
          const extras = this.getExtras(field);
          const value = this.sanitizeValue(field);
          row.fields.set(head, new TableField(TABLE_TEMPLATE[field.pfx] || field.pfx || TABLE_TEMPLATE.DEFAULT, value, tooltip, extras));
        } else {
          throw new Error(`Could not find header => ${fieldIndex}`);
        }
      }

      map.set(index, row);
    }

    return map;
  }

  /**
   * parses data into groups
   * @param groups
   * @param data
   */
  private parseGroups(data: TableResponseGroup[]): Map<number, TableGroup> {
    const map = new Map<number, TableGroup>();
    for (const [index, group] of data.entries()) {
      const _group = new TableGroup(group.value);
      _group.level = group.level;
      _group.hidden = this.parseFilters(group);
      _group.rows = this.parseRows(group.rows);
      _group.groups = this.parseGroups(group.groups);
      _group.fieldname = this.getFilterName(group);
      map.set(index, _group);
    }

    return map;
  }

  private parseAttachmentLinks(attachmentLinks: TableResponseAttachmentLink[]): AttachmentLink[] {
    return attachmentLinks.map((link) => ({
      description: link.description,
      extension: link.omsExtensie,
      filename: link.pa2Bestandsnaam,
      link: link.link,
      originalFilename: link.padOriginalFilename,
    }));
  }

  private sanitizeName(head: TableResponseHeader): string {
    switch (head.pfx) {
      default:
        return head.name.replace(/_/g, "\n");
    }
  }

  /**
   * Do api action
   */
  private async action<T>(actionid: string, actiondata = "", responseType = "json"): Promise<T> {
    return this.http.send<T>(
      ROUTES_CONFIG.actionurl,
      {
        FFWDActionID: actionid,
        actionData: actiondata,
      },
      {
        responseType: <"json">responseType,
      },
    );
  }

  /**
   * Do api action and observe response
   */
  private async actionObserve<T>(actionid: string, actiondata = "", responseType = "json"): Promise<HttpResponse<T>> {
    return this.http.sendObserve<T>(
      ROUTES_CONFIG.actionurl,
      {
        FFWDActionID: actionid,
        actionData: actiondata,
      },
      {
        responseType: <"json">responseType,
      },
    );
  }

  /**
   * Event listener on form action snapshot
   */
  private async onSnapshot(actionid: string, filename: string | null): Promise<void> {
    try {
      if (filename) {
        FileManager.download({
          blob: await this.action<Blob>(actionid, "", "blob"),
          label: filename,
        });
      } else {
        const response = await this.actionObserve<Blob>(actionid, "", "blob");
        const disposition = response.headers.get("Content-Disposition"); // TODO: update to this.getFilenameFromHeader

        if (disposition && response.body) {
          FileManager.download({
            blob: response.body,
            label: disposition.split(`filename="`)[1].split(`";`).shift() || "null",
          });
        } else {
          throw new Error("Unable to find header::Content-Disposition");
        }
      }
    } catch (e) {
      console.error("Unable to snapshot", e);
    }
  }

  /**
   * Event listener on form action redirect
   */
  private async onRedirect(contentid: string): Promise<boolean> {
    return this.application.router.navigate(["/app/content", contentid]);
  }

  /**
   * Event listener on form action action
   */
  private async onAction(actionid: string, rows: TableRow[] = []): Promise<void> {
    try {
      // const response = await this.action<ActionResponse>(actionid);
      // const parsed = <ActionResult>JSON.parse(response.postActionAsJSON);
      // this.application.onMessage(parsed.messages);

      // const formdata = await this.getFormData(fields);
      let parsed = { messages: [] };
      if (actionid.startsWith("/")) {
        //parse input

        const idps = rows.map((e) =>
          Array.from(e.fields.values())
            .filter((a) => a.template == ("idp" as TABLE_TEMPLATE))
            .map((a) => a.value.value)
            .at(0),
        );

        const input = {
          selected: idps,
        };
        //dynamic action

        const head = await fetch(`${ROUTES_CONFIG.dynactionUrl}${actionid}`, {
          method: "post",
          body: JSON.stringify(input),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.session?.id}`,
          },
        });
        if (head.headers.has("Content-Disposition")) {
          //download
          return void FileManager.downloadDyn(head);
        }
        const response = await head.json();
        parsed = response;
      } else {
        const response = await this.action<ActionResponse>(actionid);
        parsed = JSON.parse(response.postActionAsJSON);
      }

      // const parsed = <ActionResult>JSON.parse(response.postActionAsJSON);
      this.application.onMessage(parsed.messages);
    } catch (e) {
      console.error("Unable to action", e);
    }
  }

  /**
   * Event listener on form action download
   */
  private async onDownload(rows: TableRow[], filename: string | null): Promise<void> {
    try {
      if (rows.length) {
        const obj: Record<string, string> = {};
        for (const [index, row] of rows.entries()) {
          obj[`downloadlink${index}`] = row.downloadlink;
        }

        if (filename) {
          FileManager.download({
            blob: await this.action<Blob>(Object.values(obj)[0], JSON.stringify(obj), "blob"),
            label: filename,
          });
        } else {
          const response = await this.actionObserve<Blob>(Object.values(obj)[0], JSON.stringify(obj), "blob");
          const disposition = response.headers.get("Content-Disposition");

          if (disposition && response.body) {
            FileManager.download({
              blob: response.body,
              label: disposition.split(`filename="`)[1].split(`";`).shift() || "null", // TODO: update to this.getFilenameFromHeader
            });
          } else {
            throw new Error("Unable to find header::Content-Disposition");
          }
        }
      } else {
        throw new Error("No selected rows.");
      }
    } catch (e) {
      console.error("Unable to download", e);
    }
  }

  /**
   * Event listener on table action archive
   */
  private async onArchive(): Promise<void> {
    throw new Error("Method not implemented.");
  }

  /**
   * Event listener on table action delete
   */
}
